import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Grid from "@material-ui/core/Grid"
import useExternalLinkHandler from "../../hooks/useExternalLinkHandler"

const FooterSdgNav = () => {
  const { showExternalLinkConfirmation } = useExternalLinkHandler()
  const { allWpSdg } = useStaticQuery(graphql`
    {
      allWpSdg(sort: { fields: menuOrder, order: ASC }) {
        nodes {
          id
          title
          slug
          featuredImage {
            node {
              localFile {
                publicURL
              }
            }
          }
          SdgMeta {
            externeUrl
          }
        }
      }
    }
  `)

  //console.log(allWpSdg)

  return (
    <Grid
      className="welcome-footer-sdg-nav"
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      {allWpSdg.nodes.map(node => {
        if (!node.featuredImage) {
          return null
        }

        //console.log(node)
        return (
          <Grid
            item
            key={node.id + node.slug}
            className="welcome-footer-sdg-nav-item"
          >
            <a
              href={node.SdgMeta.externeUrl}
              onClick={e => showExternalLinkConfirmation(e, true)}
            >
              <img
                src={node.featuredImage.node.localFile.publicURL}
                alt={node.title}
                width={63}
              />
            </a>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default FooterSdgNav

import React, { useState } from "react"
import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import FooterSdgNav from "../components/welcome/FooterSdgNav"
import Seo from "../components/seo"
import { Link } from "gatsby"
import useUser from "../hooks/useUser"
import Earth from "../../static/images/earth.inline.svg"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import MailIcon from "@material-ui/icons/Mail"
import LanguageIcon from "@material-ui/icons/Language"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import GroupIcon from "@material-ui/icons/Group"
import { usePWAInstall } from "react-use-pwa-install"
import { useIosPwaInstaller } from "../hooks/useIosPwaInstaller"
import useGameConfig from "../hooks/useGameConfig"
import useExternalLinkHandler from "../hooks/useExternalLinkHandler"

const IndexPage = () => {
  const theme = useTheme()
  const { signedIn } = useUser()
  const screen_sm_up = useMediaQuery(theme.breakpoints.up("lg"))
  const install = usePWAInstall()
  const [showIosInstallDialog, setShowIosInstallDialog] = useState(false)
  const { isIos, isInStandaloneMode, renderIosInstallDialog } =
    useIosPwaInstaller(setShowIosInstallDialog)
  const { generalSettings } = useGameConfig()
  const { showExternalLinkConfirmation } = useExternalLinkHandler()
  const {
    texts: { notificationTexts, others },
  } = useGameConfig()
  const {
    menuConfig: { hamburgerMenu },
  } = useGameConfig()

  function handlePwaIosInstall() {
    setShowIosInstallDialog(true)
  }

  function isIosInstallable() {
    return isIos() && !isInStandaloneMode()
  }

  return (
    <Layout>
      <Seo
        title={`${generalSettings.title} - ${generalSettings.description}`}
      />
      <Grid container className="welcome-page">
        <Grid item xs={12} lg={6} className="welcome-column-left">
          <Grid item>
            <h1 className="welcome-main-title">{generalSettings.title}</h1>
            <h2 className="welcome-sub-title">{generalSettings.description}</h2>
          </Grid>
          <Grid
            spacing={3}
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
          >
            <Grid
              container
              direction="row"
              alignItems="stretch"
              item
              xs={12}
              md={12}
              lg={5}
              spacing={1}
              className="welcome-buttons-container"
            >
              {!signedIn && (
                <Grid item xs={12}>
                  <Link to="/login">
                    <Button variant="contained" color="primary">
                      {notificationTexts.startGame}
                    </Button>
                  </Link>
                </Grid>
              )}
              {signedIn && (
                <Grid item xs={12}>
                  <Link to="/app/overview">
                    <Button variant="contained" color="primary">
                      {notificationTexts.myOverview}
                    </Button>
                  </Link>
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={6} lg={12}>
                <Link to="/spielanleitung">
                  <Button variant="contained">
                    {notificationTexts.instructions}
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={12}>
                <Link to="/news">
                  <Button variant="contained">{others.news}</Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={12}>
                <Link to="/was-ist-der-wirkel">
                  <Button variant="contained">
                    {notificationTexts.whatIsIt}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              lg={5}
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <a
                  href={`mailto:${hamburgerMenu.emailAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="welcome-contact-icons">
                    <MailIcon className="welcome-contact-icons" />
                  </div>
                </a>
              </Grid>
              <Grid item>
                <a
                  href="https://www.renn-netzwerk.de/west"
                  rel="noreferrer"
                  onClick={e => showExternalLinkConfirmation(e, true)}
                >
                  <div className="welcome-contact-icons">
                    <LanguageIcon />
                  </div>
                </a>
              </Grid>
              {isIosInstallable() && (
                <Grid item>
                  <div
                    className="welcome-contact-icons"
                    onClick={handlePwaIosInstall}
                  >
                    <InboxIcon />
                  </div>
                </Grid>
              )}
              {install && (
                <Grid item>
                  <div className="welcome-contact-icons" onClick={install}>
                    <InboxIcon />
                  </div>
                </Grid>
              )}
                <Grid item>
                  <div className="welcome-contact-icons">
                      <a href="https://gemeinschaftswerk-nachhaltigkeit.de/" rel="noreferrer" onClick={e => showExternalLinkConfirmation(e, true)}>
                        <GroupIcon />
                      </a>
                  </div>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} lg={4} className="welcome-column-right">
          <Earth style={{ paddingBottom: "2em" }} />
        </Grid>
      </Grid>
      {screen_sm_up && (
        <Grid container>
          <Grid item md={12} lg={12}>
            <FooterSdgNav />
          </Grid>
        </Grid>
      )}
      {renderIosInstallDialog(showIosInstallDialog)}
    </Layout>
  )
}

export default IndexPage
